<template>
  <div class="kura_tm_section" id="portfolio">
    <div class="kura_tm_news">
      <div class="container">
        <div class="kura_tm_main_title image__title">
          <span>Portfolio</span>
          <h3>Portfolio</h3>
        </div>
        <div class="news_list wow fadeInUp" data-wow-duration=".7s">
          <swiper :options="swiperOptions" ref="mySwiper">
            <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/portfolio/410-460.jpg" alt="" />
                  <div
                    class="main image_"
                    data-img-url="img/portfolio/graninges.png"
                  ></div>
                </div>
                <div class="overlay"></div>
                <img class="svg" src="img/svg/right-arrow.svg" alt="" />
                <div class="details">
                  <a class="link" href="http://graningeshoes.com/">
                    <span class="date">Graninge</span></a
                  >
                  <h3 class="span">E-handel Projekt</h3>
                </div>
                <a class="kura_tm_full_link_ kura_tm_full_link" href="#"></a>
                <div class="news_hidden_details">
                  <div class="news_popup_informations">
                    <div class="description">
                      <p>
                        Ett projekt inom e-handel där jag utvecklade ett
                        skräddarsytt WordPress-tema och integrerade plugins.
                        Inkluderade arbete med WooCommerce samt integration av
                        betalningslösningar som Klarna och Nshift.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/portfolio/410-460.jpg" alt="" />
                  <div
                    class="main image_"
                    data-img-url="img/portfolio/FDSE.png"
                  ></div>
                </div>
                <div class="overlay"></div>
                <img class="svg" src="img/svg/right-arrow.svg" alt="" />
                <div class="details">
                  <a
                    class="link"
                    href="http://www.sustainablefashionacademy.org"
                  >
                    <span class="date">Sustainablefashionacademy</span></a
                  >
                  <h3 class="span">
                    Frilans som fullstack utvecklare på FDSE AB
                  </h3>
                </div>
                <a class="kura_tm_full_link_ kura_tm_full_link" href="#"></a>
                <div class="news_hidden_details">
                  <div class="news_popup_informations">
                    <div class="description">
                      <p>
                        Arbetade som frilans Fullstack-utvecklare. Mitt ansvar
                        inkluderade utveckling av sidmallar och förbättring av
                        webbplatsens funktionalitet, med fokus på användning av
                        Wordpress, SASS och PHP som primära verktyg.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/portfolio/410-460.jpg" alt="" />
                  <div
                    class="main image_"
                    data-img-url="img/portfolio/contkit.png"
                  ></div>
                </div>
                <div class="overlay"></div>
                <img class="svg" src="img/svg/right-arrow.svg" alt="" />
                <div class="details">
                  <a class="link" href="https://dashboard.contkit.com/">
                    <span class="date">Contkit</span></a
                  >
                  <h3 class="span">Frilans uppdrag</h3>
                </div>
                <a class="kura_tm_full_link_ kura_tm_full_link" href="#"></a>
                <div class="news_hidden_details">
                  <div class="news_popup_informations">
                    <div class="description">
                      <p>
                        Ett projekt för att skapa ett administrationsdashboard
                        för hantering av affiliatelänkar. Jag hade ansvaret för
                        front-end utvecklingen, där jag använde tekniker såsom
                        JavaScript, TailwindCSS och Alpine.js.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            
                   <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/portfolio/410-460.jpg" alt="" />
                  <div
                    class="main image_"
                    data-img-url="img/portfolio/phoslabs.png"
                  ></div>
                </div>
                <div class="overlay"></div>
                <img class="svg" src="img/svg/right-arrow.svg" alt="" />
                <div class="details">
                  <a
                    class="link"
                    href="https://phoslabs.patprasopsap.se/"
                  >
                    <span class="date">Ett hobbyprojekt</span></a
                  >
                  <h3 class="span">Skapa ett eget WordPress-tema med ACF och Gutenberg.
                  </h3>
                </div>
                <a class="kura_tm_full_link_ kura_tm_full_link" href="#"></a>
                <div class="news_hidden_details">
                  <div class="news_popup_informations">
                    <div class="description">
                    
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/portfolio/410-460.jpg" alt="" />
                  <div
                    class="main image_"
                    data-img-url="img/portfolio/apt.png"
                  ></div>
                </div>
                <div class="overlay"></div>
                <img class="svg" src="img/svg/right-arrow.svg" alt="" />
                <div class="details">
                  <a class="link" href="https://apartment-client-90f423f1de46.herokuapp.com/">
                    <span class="date">Ett hobbyprojekt</span></a
                  >
                  <h3 class="span">
                   Ett fullstack-projekt utvecklat med React och Node.js.
                  </h3>
                </div>
                <a class="kura_tm_full_link_ kura_tm_full_link" href="#"></a>
                <div class="news_hidden_details">
                  <div class="news_popup_informations">
                    <div class="description">
                      <p>
                       Ett fullstack-projekt utvecklat med React och Node.js.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="kura_tm_swiper_progress fill">
            <div class="my_pagination_in">
              <span class="current"></span>
              <!-- <span class="pagination_progress"
                ><span class="all"><span class="date"></span></span
              ></span> -->
              <span class="total"></span>
            </div>
            <div class="my_navigation">
              <ul>
                <li>
                  <a class="my_prev" href="#"
                    ><img class="svg" src="img/svg/right-arrow.svg" alt=""
                  /></a>
                </li>
                <li>
                  <a class="my_next" href="#"
                    ><img class="svg" src="img/svg/right-arrow.svg" alt=""
                  /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "News",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 30,
        loop: true,
        pagination: false,
        navigation: {
          prevEl: ".my_prev",
          nextEl: ".my_next",
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        },
        // Some Swiper option/callback...
      },
      images: [
        {
          src: "/img/portfolio/1.jpg",
          thumbnail: "/img/portfolio/1.jpg",
          title: "Magic Art",
          sub: "Image",
        },
        {
          src: "/img/portfolio/2.jpg",
          thumbnail: "/img/portfolio/2.jpg",
          title: "Bona Green",
          sub: "Image",
        },

        {
          src: "/img/portfolio/3.jpg",
          thumbnail: "/img/portfolio/3.jpg",
          title: "Leo Dandora",
          sub: "Image",
        },
        {
          src: "/img/portfolio/4.jpg",
          thumbnail: "/img/portfolio/4.jpg",
          title: "Folio Grasia",
          sub: "Image",
        },
        {
          src: "/img/portfolio/5.jpg",
          thumbnail: "/img/portfolio/5.jpg",
          title: "Viva Mercury",
          sub: "Image",
        },
        {
          src: "/img/portfolio/6.jpg",
          thumbnail: "/img/portfolio/6.jpg",
          title: "Santa Onera",
          sub: "Image",
        },
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    // console.log("Current Swiper instance object", this.swiper);
    this.swiper.slideTo(3, 1000, false);
  },
};
</script>
