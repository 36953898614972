<template>
  <div class="kura_tm_section" id="education">
    <div class="kura_tm_pricing">
      <div class="container">
        <div class="pricing_inner">
          <div class="left">
            <div class="kura_tm_sticky_section">
              <div class="kura_tm_main_title">
                <span>{{ $t('education.education') }} </span>
                <h3>{{ $t('education.education') }}</h3>
              </div>
              <div class="text"></div>
            </div>
          </div>
          <div class="right">
            <div class="kura_tm_sticky_section">
              <ul>
                <li class="wow fadeInUp" data-wow-duration=".7s">
                  <div class="list_inner">
                    <div class="title">
                      <span>Nackademin</span>
                    </div>
                    <div class="cost">
                      <span>
                       {{ $t('education.education1') }}
                      </span>
                    </div>
                    <div class="cost">
                      <span> 2019 - 2021</span>
                    </div>
                  </div>
                </li>
                <li
                  class="wow fadeInUp"
                  data-wow-duration=".7s"
                  data-wow-delay=".2s"
                >
                  <div class="list_inner">
                    <div class="title">
                      <span>Stockholms universitet</span>
                    </div>
                    <div class="cost">
                      <span
                        >{{ $t('education.education2') }}
                      </span>
                    </div>
                    <div class="cost">
                      <span> 2015 - 2018</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pricing",
};
</script>
