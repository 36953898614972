var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kura_tm_section",attrs:{"id":"timeline"}},[_c('div',{staticClass:"kura_tm_timeline"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"kura_tm_main_title"},[_c('span',[_vm._v(_vm._s(_vm.$t('experience.experience')))]),_c('h3',[_vm._v(_vm._s(_vm.$t('experience.work_experience')))])]),_c('div',{staticClass:"timeline_list"},[_c('ul',[_c('li',{staticClass:"wow fadeInUp",attrs:{"data-wow-duration":".7s"}},[_c('div',{staticClass:"list_inner"},[_c('span',[_vm._v("Jan, 2023 - "+_vm._s(_vm.$t('experience.ongoing')))])]),_c('div',{staticClass:"list_inner"},[_c('span',[_vm._v(_vm._s(_vm.$t('experience.role')))])]),_vm._m(0)]),_c('li',{staticClass:"wow fadeInUp",attrs:{"data-wow-duration":".7s"}},[_vm._m(1),_c('div',{staticClass:"list_inner"},[_c('span',[_vm._v(_vm._s(_vm.$t('experience.fullstack')))])]),_vm._m(2)]),_c('li',{staticClass:"wow fadeInUp",attrs:{"data-wow-duration":".7s","data-wow-delay":".8s"}},[_vm._m(3),_c('div',{staticClass:"list_inner"},[_c('span',[_vm._v(_vm._s(_vm.$t('experience.role')))])]),_vm._m(4)]),_c('li',{staticClass:"wow fadeInUp",attrs:{"data-wow-duration":".7s","data-wow-delay":".2s"}},[_vm._m(5),_c('div',{staticClass:"list_inner"},[_c('span',[_vm._v(_vm._s(_vm.$t('experience.fullstack')))])]),_vm._m(6)]),_c('li',{staticClass:"wow fadeInUp",attrs:{"data-wow-duration":".7s","data-wow-delay":".4s"}},[_vm._m(7),_c('div',{staticClass:"list_inner"},[_c('span',[_vm._v(_vm._s(_vm.$t('experience.fullstack'))+" ("+_vm._s(_vm.$t('experience.intern'))+")")])]),_vm._m(8)]),_c('li',{staticClass:"wow fadeInUp",attrs:{"data-wow-duration":".7s","data-wow-delay":".6s"}},[_vm._m(9),_c('div',{staticClass:"list_inner"},[_c('span',[_vm._v(_vm._s(_vm.$t('experience.fullstack'))+" ("+_vm._s(_vm.$t('experience.intern'))+") ")])]),_vm._m(10)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list_inner"},[_c('span',[_vm._v("Verindex Sweden AB")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list_inner"},[_c('span',[_vm._v("Apr, 2023 - Okt, 2023")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list_inner"},[_c('span',[_vm._v("Lightweb AB")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list_inner"},[_c('span',[_vm._v("Okt, 2022 - Dec, 2023")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list_inner"},[_c('span',[_vm._v("FDSE AB (Sustainable Fashion Academy)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list_inner"},[_c('span',[_vm._v("Okt, 2021 - Mars, 2022")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list_inner"},[_c('span',[_vm._v("K-mit AB")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list_inner"},[_c('span',[_vm._v("Jan, 2021 - Apr, 2021")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list_inner"},[_c('span',[_vm._v("K-mit AB ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list_inner"},[_c('span',[_vm._v("Mars, 2020 - Jun, 2020")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list_inner"},[_c('span',[_vm._v("Emmio AB")])])
}]

export { render, staticRenderFns }