import "swiper/css/swiper.css";
import Vue from "vue";
import {Plugin} from "vue-fragment";
import App from "./App.vue";
import router from "./router.js";
import "/public/css/main.css";
import VueI18n from 'vue-i18n';
import messages from './locales';

Vue.config.productionTip = false;
Vue.use(Plugin);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'sv', // set locale
  fallbackLocale: 'sv', // set fallback locale
  messages, // set locale messages
});

new Vue({
  i18n,
  router,
  render: (h) => h(App)
}).$mount("#app");
