<template>
  <div>
    <ul id="anchor_nav">
      <li :class="{ opened: isOpened }"><a href="#home">{{ $t('menu.home') }}</a></li>
      <li :class="{ opened: isOpened }"><a href="#skills">{{ $t('menu.skills') }}</a></li>
      <li :class="{ opened: isOpened }"><a href="#timeline">{{ $t('menu.timeline') }}</a></li>
      <li :class="{ opened: isOpened }"><a href="#education">{{ $t('menu.education') }}</a></li>
      <li :class="{ opened: isOpened }"><a href="#portfolio">{{ $t('menu.portfolio') }}</a></li>
      <li :class="{ opened: isOpened }"><a href="#contact">{{ $t('menu.contact') }}</a></li>
      <li class="dropdown" @click="toggleDropdown">
        <a href="#">{{ $t('language') }}</a>
        <ul class="change-lang" v-show="dropdownOpen">
          <li><button @click="changeLocale('en')">English</button></li>
          <li><button @click="changeLocale('sv')">Swedish</button></li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Menu",
  data() {
    return {
      isOpened: false,
      dropdownOpen: false,
    };
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.dropdownOpen = false; // Close the dropdown after selecting a language
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
        handleClickOutside(event) {
      if (this.dropdownOpen && !this.$el.contains(event.target)) {
        this.dropdownOpen = false;
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style scoped>
.dropdown ul {
  list-style: none;
  padding: 0;
}
.dropdown li {
  display: inline;
  margin-right: 10px;
  position: relative;
  opacity:1 !important;
  visibility:visible !important;
}
button {
  margin-right: 5px;
}
.opened {
  color: red; /* Example style for opened class */
}
.dropdown ul {
  
  position: absolute;
  top: 100%;
  left: 0;
  list-style: none;
  padding: 0;
  margin: 0;
}
.dropdown ul li {
  margin: 0;
}
.dropdown ul li button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
}
.dropdown ul li button:hover {
    color: #ff4522;
}
</style>
