<template>
  <div></div>
</template>

<script>
export default {
  name: "Settings",
  data() {
    return {
      show: false,
      cursor: 1,
    };
  },
  mounted() {
    //   Color option
    const colors = document.querySelectorAll("#colors li a");
    colors.forEach((color) => {
      color.addEventListener("click", () => {
        document
          .querySelector(".kura_tm_all_wrap")
          .setAttribute("data-color", color.classList[0]);
      });
    });
  },
  methods: {
    cursorFun(value) {
      this.cursor = value;
      document
        .querySelector(".kura_tm_all_wrap")
        .setAttribute("data-magic-cursor", value === 1 ? "show" : "hide");
    },
  },
};
</script>
