<template>
  <div class="kura_tm_modalbox">
    <div class="box_inner">
      <div class="close">
        <a href="#"><img class="svg" src="img/svg/cancel.svg" alt="" /></a>
      </div>
      <div class="description_wrap">
        <div class="news_popup_informations"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalBox",
};
</script>
