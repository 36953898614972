<template>
  <div class="kura_tm_section" id="contact">
    <div class="kura_tm_contact">
      <div class="container">
        <div class="kura_tm_main_title">
          <span>{{ $t('contact.title') }}</span>
          <h3>{{ $t('contact.subtitle') }}</h3>
        </div>
        <div class="contact_inner">
          <div class="left wow fadeInUp" data-wow-duration=".7s">
            <div class="text">
              <p>
                {{ $t('contact.form_instruction') }}
              </p>
            </div>
            <div class="short">
              <ul>
                <li>
                  <div class="list_inner">
                    <img class="svg" src="img/svg/placeholder.svg" alt="" />
                    <span>{{ $t('contact.location') }}</span>
                  </div>
                </li>
                <li>
                  <div class="list_inner">
                    <img class="svg" src="img/svg/phone.svg" alt="" />
                    <span><a href="tel:+46-738-42-31-35">{{ $t('contact.phone') }}</a></span>
                  </div>
                </li>
                <li>
                  <div class="list_inner">
                    <img class="svg" src="img/svg/mail.svg" alt="" />
                    <span><a href="mailto:sipapat.prasopsap@gmail.com">{{ $t('contact.email') }}</a></span>
                  </div>
                </li>
                <li>
                  <div class="list_inner">
                    <img class="svg" src="img/svg/globe.svg" alt="" />
                    <span><a href="https://www.patprasopsap.se" target="_blank">{{ $t('contact.website') }}</a></span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="right wow fadeInUp" data-wow-duration=".7s">
            <div class="fields">
              <form
                action="/"
                method="post"
                class="contact_form"
                id="contact_form"
                autocomplete="off"
                @submit.prevent="submit"
              >
                <div class="returnmessage" :data-success="$t('contact.form_success')"></div>
                <div class="empty_notice">
                  <span>{{ $t('contact.form_error') }}</span>
                </div>
                <div class="first">
                  <ul>
                    <li>
                      <input id="firstname" v-model="firstname" type="text" :placeholder="$t('contact.name_placeholder')" />
                    </li>
                    <li>
                      <input id="email" v-model="email" type="text" :placeholder="$t('contact.email_placeholder')" />
                    </li>
                  </ul>
                </div>
                <div class="last">
                  <textarea id="message" v-model="message" :placeholder="$t('contact.message_placeholder')"></textarea>
                </div>
                <div class="kura_tm_button" data-position="left">
                  <a id="send_message" @click="submit" href="#"
                    ><span>{{ $t('contact.submit_button') }}</span>
                    <img class="svg" src="img/svg/right-arrow.svg" alt=""
                  /></a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db, timestamp } from "../firebase/init";
import { collection, addDoc } from "firebase/firestore";
export default {
  name: "Contact",
  data() {
    return {
      snackbar: false,
      text: "Thank you for your message! :)",
      valid: false,
      firstname: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 20 || "Name must be less than 20 characters",
      ],
      email: "",
      message: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  methods: {
    async submit() {
      try {
        await addDoc(collection(db, "contact"), {
          firstname: this.firstname,
          email: this.email,
          message: this.message,
          timestamp: timestamp,
        });
        this.clear();
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    },
    clear() {
      this.firstname = "";
      this.email = "";
      this.message = "";
    },
  },
};
</script>
