<template>
  <div class="kura_tm_section" id="timeline">
    <div class="kura_tm_timeline">
      <div class="container">
        <div class="kura_tm_main_title">
          <span>{{ $t('experience.experience') }}</span>
          <h3>{{ $t('experience.work_experience') }}</h3>
        </div>
        <div class="timeline_list">
          <ul>
            <li class="wow fadeInUp" data-wow-duration=".7s">
              <div class="list_inner">
                <span>Jan, 2023 - {{ $t('experience.ongoing') }}</span>
              </div>
              <div class="list_inner">
                <span>{{ $t('experience.role') }}</span>
              </div>
              <div class="list_inner">
                <span>Verindex Sweden AB</span>
              </div>
            </li>
                 <li class="wow fadeInUp" data-wow-duration=".7s">
              <div class="list_inner">
                <span>Apr, 2023 - Okt, 2023</span>
              </div>
              <div class="list_inner">
                <span>{{ $t('experience.fullstack') }}</span>
              </div>
              <div class="list_inner">
                <span>Lightweb AB</span>
              </div>
            </li>
                <li
              class="wow fadeInUp"
              data-wow-duration=".7s"
              data-wow-delay=".8s"
            >
              <div class="list_inner">
                <span>Okt, 2022 - Dec, 2023</span>
              </div>
              <div class="list_inner">
                <span>{{ $t('experience.role') }}</span>
              </div>
              <div class="list_inner">
                <span>FDSE AB (Sustainable Fashion Academy)</span>
              </div>
            </li>
            <li
              class="wow fadeInUp"
              data-wow-duration=".7s"
              data-wow-delay=".2s"
            >
              <div class="list_inner">
                <span>Okt, 2021 - Mars, 2022</span>
              </div>
              <div class="list_inner">
                <span>{{ $t('experience.fullstack') }}</span>
              </div>
              <div class="list_inner">
                <span>K-mit AB</span>
              </div>
            </li>
            <li
              class="wow fadeInUp"
              data-wow-duration=".7s"
              data-wow-delay=".4s"
            >
              <div class="list_inner">
                <span>Jan, 2021 - Apr, 2021</span>
              </div>
              <div class="list_inner">
                <span>{{ $t('experience.fullstack') }} ({{ $t('experience.intern') }})</span>
              </div>
              <div class="list_inner">
                <span>K-mit AB </span>
              </div>
            </li>
            <li
              class="wow fadeInUp"
              data-wow-duration=".7s"
              data-wow-delay=".6s"
            >
              <div class="list_inner">
                <span>Mars, 2020 - Jun, 2020</span>
              </div>
              <div class="list_inner">
                <span>{{ $t('experience.fullstack') }} ({{ $t('experience.intern') }}) </span>
              </div>
              <div class="list_inner">
                <span>Emmio AB</span>
              </div>
            </li>
        
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeLine",
};
</script>
