<template>
  <div class="kura_tm_section" id="home">
    <div class="kura_tm_hero">
      <div class="container">
        <div class="content">
          <div class="left">
            <span class="name">{{ $t('name') }}</span>
            <h3 class="job">{{ $t('job') }}</h3>
            <span class="span text-black">{{ $t('about_me') }}</span>
            <div class="text">
              <p>
                {{ $t('description') }}
              </p>
            </div>
          </div>
          <div class="right">
            <div class="image">
              <img src="/img/thumbs/3-4.jpg" alt="" />
              <div class="main" data-img-url="/img/intro/IMG_1128.png" />
              <div class="shape"></div>
            </div>
          </div>
          <div class="down anchor">
            <a href="#timeline"
              ><img class="svg" src="/img/svg/down-arrow.svg" alt=""
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped>
/* Add any additional styles if necessary */
</style>
