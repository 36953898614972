<template>
  <div class="kura_tm_section" id="skills">
    <div class="kura_tm_skills">
      <div class="container">
        <div class="skills_inner">
          <div class="left">
            <div class="kura_tm_main_title light">
              <span>{{ $t('skills.technologies') }}</span>
              <h3>{{ $t('skills.programming') }}</h3>
            </div>
            <div class="text wow fadeInUp" data-wow-duration=".7s"></div>
            <div
              class="dodo_progress wow fadeInUp"
              data-wow-duration=".7s"
              data-wow-delay=".2s"
            >
              <div class="progress_inner" data-value="90">
                <span
                  ><span class="label">HTML &amp; CSS</span
                  ><span class="number">90%</span></span
                >
                <div class="background">
                  <div class="bar">
                    <div class="bar_in" style="width: 90%"></div>
                  </div>
                </div>
              </div>
              <div class="progress_inner" data-value="80">
                <span
                  ><span class="label">JavaScript</span
                  ><span class="number">80%</span></span
                >
                <div class="background">
                  <div class="bar">
                    <div class="bar_in" style="width: 80%"></div>
                  </div>
                </div>
              </div>
              <div class="progress_inner" data-value="90">
                <span
                  ><span class="label">WordPress</span
                  ><span class="number">80%</span></span
                >
                <div class="background">
                  <div class="bar">
                    <div class="bar_in" style="width: 80%"></div>
                  </div>
                </div>
              </div>
              <div class="progress_inner" data-value="70">
                <span
                  ><span class="label">SQL, MongoDB</span
                  ><span class="number">70%</span></span
                >
                <div class="background">
                  <div class="bar">
                    <div class="bar_in" style="width: 70%"></div>
                  </div>
                </div>
              </div>
              <div class="progress_inner" data-value="70">
                <span
                  ><span class="label">PHP</span
                  ><span class="number">70%</span></span
                >
                <div class="background">
                  <div class="bar">
                    <div class="bar_in" style="width: 70%"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div
              class="my_image jarallax"
              data-speed="0"
              data-img-url="/img/service/IMG_0596.jpg"
            >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { activeSkillProgress, jarallaxContent } from "../utils/utils";

export default {
  name: "Skills",
  mounted() {
    window.addEventListener("scroll", activeSkillProgress);
    jarallaxContent();
  },
};
</script>
