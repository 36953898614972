<template>
  <fragment>
    <audio id="audio1"><source src="/audio/1.mp3" /></audio>
    <audio id="audio2"><source src="/audio/2.mp3" /></audio>
  </fragment>
</template>

<script>
export default {
  name: "Audio",
};
</script>
