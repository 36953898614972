<template>
  <div class="kura_tm_section">
    <div class="kura_tm_copyright">
      <div class="container">
        <div class="copyright_inner wow fadeInUp" data-wow-duration=".7s">
          <div class="text">
            <p>
              Copyright &copy; {{ new Date().getFullYear() }}. All rights
              reserved.
            </p>
          </div>
          <div class="social">
            <ul>
              <li>
                <a href="https://github.com/sipapiga" target="_blank"
                  ><svg-icon type="mdi" :path="github"></svg-icon></a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/sipapat-prasopsap-054442b3" target="_blank"><svg-icon type="mdi" :path="linkedIn"></svg-icon></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiLinkedin,mdiGithub } from '@mdi/js';

export default {
  name: "Footer",
  
  components: {
    SvgIcon
  },
  data() {
    return {
      linkedIn: mdiLinkedin,
      github:mdiGithub,
    };
  },
};
</script>
